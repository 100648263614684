<template>
  <div class="mx-2 mb-2">
    <b-row>
      <b-col
        cols="12"
        sm="12"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <label class="mr-1">{{ $t('generic.rowsPerPage') }}</label>
        <b-form-select
          v-model="paginationData.perPage"
          :options="perPageOptions"
          class="per-page-selector d-inline-block mx-20 mr-1"
          @click="$emit('pagination-data', paginationData)"
        />

        <span class="mr-1">
          {{ dataList.from }} - {{ dataList.to }} {{ $t('generic.of') }} {{ dataList.total }}
        </span>

        <b-pagination
          v-model="paginationData.page"
          :total-rows="dataList.total"
          :per-page="paginationData.perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @click="$emit('pagination-data', paginationData)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<script>

export default {
  name: 'AppPaginator',
  props: {
    dataList: {
      type: [Array, Object],
      default: null,
    },
  },
  data() {
    return {
      paginationData: {
        page: 1,
        perPage: 15,
      },
      perPageOptions: [15, 25, 50, 100],
    }
  },
  watch: {
    'paginationData.page': function paginationDataPage() {
      this.$emit('pagination-data', this.paginationData)
    },
    'paginationData.perPage': function paginationDataPerPage() {
      this.$emit('pagination-data', this.paginationData)
    },
  },
  // mounted() {
  //   this.$emit('pagination-data', this.paginationData)
  // },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 70px;
}
</style>
