<template>
  <b-row class="mt-5">
    <b-col
      sm="8"
      md="6"
      lg="4"
      class="offset-sm-2 offset-md-3 offset-lg-4 text-center"
    >
      <h3 class="mb-1">
        {{ textOne }}
      </h3>
      <p class="mt-3">
        {{ textTwo }}
      </p>
      <b-button
        variant="primary"
        pill
        class="mt-3"
        :style="colorPrimaryBtn"
        @click="$emit('show-register-component', 'create')"
      >
        &nbsp;&nbsp;{{ textInButton }}&nbsp;&nbsp;
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },

  props: {
    textOne: {
      type: String,
      default: null,
    },
    textTwo: {
      type: String,
      default: null,
    },
    textInButton: {
      type: String,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
